define(['app'], function(app) {

  const socialProviderButtons = function() {

    const component = {};

    var _config = {
      selectors: {
        button: '[data-js-element="button"]'
      }
    };

    const _init = function(element) {
      component.element = element;
      component.button = document.querySelectorAll(component.config.selectors.button);
      component.bind();
      return component;
    };

    const _bind = function() {
      component.element.addEventListener('click', component.socialProviderButtonsClicked);
    };

    const _socialProviderButtonsClicked = function(event) {
      app.publish('tracking/record', 'Login', 'Clicked', event.target.innerText);
    };

    component.config = _config;
    component.init = _init;
    component.bind = _bind;
    component.socialProviderButtonsClicked = _socialProviderButtonsClicked;
    return component;
  };

  return socialProviderButtons;
});
